<template>
  <div>
    <div class="screen-box">
      <div class="screenType">
        <label>类型</label>
        <a-select :default-value="typeValue" style="width: 120px" @change="handleChange">
          <a-select-option :value="item.value" v-for="(item,index) in type" :key="index">
            {{item.name}}
          </a-select-option>
         
        </a-select>
      </div>
      <div class="screenTime">
        <label>时间</label>
        <a-month-picker placeholder="筛选时间"  @change="onChange" />
      </div>
    </div>
    <a-table
      :columns="columns"
      rowKey="odno"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template slot="ordertype" slot-scope="ordertype">
        {{ transformType(ordertype) }}
      </template>
    </a-table>
  </div>
</template>
<script>
import { getXeuorder } from "@/api/manage";

// const columns = [
//   {
//     title: "订单号",
//     dataIndex: "odno",
//     width: "10%",
//     scopedSlots: { customRender: "odno" },
//   },
//   {
//     title: "类型",
//     dataIndex: "ordertype",
//     width: "20%",
//     scopedSlots: { customRender: "ordertype" },
//   },
//   {
//     title: "实际消费金额",
//     dataIndex: "actualamount",
//     width: "20%",
//   },
//   {
//     title: "备注",
//     dataIndex: "UPDATED_TIME",
//     width: "20%",
//     customRender: (text, row) => {
//       return (<span>{row.payamount} {row.actualamount}</span>)
//     },
//   },
//   {
//     title: "消费时间",
//     dataIndex: "UPDATED_TIME",
//   },
// ];

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      typeValue:'',
      time:'',
      type:[{name:'全部',value:''},{name:'购买模板',value:'tpl'},{name:'购买会员',value:'vip'},{name:'购买优惠券',value:'coupon'}],
      columns: [
  {
    title: "订单号",
    dataIndex: "odno",
    width: "10%",
    scopedSlots: { customRender: "odno" },
  },
  {
    title: "类型",
    dataIndex: "ordertype",
    width: "20%",
    scopedSlots: { customRender: "ordertype" },
  },
  {
    title: "实际消费金额",
    dataIndex: "actualamount",
    width: "20%",
  },
  {
    title: "备注",
    dataIndex: "remarks",
    width: "20%",
    customRender: (text, row) => {
      return (<span>订单总额{row.payamount}  优惠{(row.payamount-row.actualamount).toFixed(2)}元</span>)
    },
  },
  {
    title: "消费时间",
    dataIndex: "UPDATED_TIME",
  },
],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        pageNo: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    handleChange(value) {
      this.typeValue=value
      this.fetch();
    },
    onChange(date, dateString) {
      this.time=dateString
      this.fetch();
      // console.log(date, dateString);
    },
    fetch(params = {}) {
      this.loading = true;
      getXeuorder({
        pageSize: 10,
        time: this.time,
        type:this.typeValue,
        // results: 10,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.data = data.data;
        this.pagination = pagination;
      });
    },
    transformType(type) {
      switch (type) {
        case 0:
          return "购买模板";
          // break;
        case 1:
          return "购买会员";
          // break;
        case 2:
          return "购买优惠劵";
          // break;

        default:
          return "";
          // break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.screen-box{
  display: flex;
  margin-bottom: 18px;
  .screenType,.screenTime{
    margin-right: 60px;
    label{
      margin-right: 12px;
    }
  }
}
</style>